// 个人中心
import service from '@/utils/request'
// 获取用户基础信息
export function getDetail(params) {
  return service({
    url: '/user',
    method: 'get',
    params
  });
}
// 修改用户信息
export function updateUserinfo(data) {
    return service({
      url: '/user/Client',
      method: 'post',
      data
    });
}
// 校验用户登录密码
export function ClientPwd(params) {
  return service({
    url: '/user/Client/password',
    method: 'post',
    params
  });
}
// 修改手机号获取验证码
export function getCodes(params) {
  return service({
    url: '/user/getupdatePhone/code',
    method: 'get',
    params
  });
}
// 获取用户设置的密保问题
export function getSecret(params) {
  return service({
    url: '/user/security',
    method: 'get',
    params
  });
}
// 修改用户设置的密保
export function updateSecret(data) {
  return service({
    url: '/user/save/security',
    method: 'post',
    data
  });
}
// 修改密码
export function updatePwd(params) {
  return service({
    url: '/user/Client/update/password',
    method: 'post',
    params
  });
}