<template>
  <div class="changeTel">
    <div v-show="telStatus === 1">
      <el-form :model="NewForm" ref="threeRuleForm" class="demo-ruleForm">
        <el-form-item>
          <span>原手机号</span>
          <br />
          <el-input
            v-model="NewForm.telephone"
            placeholder="未绑定"
            disabled
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="btn">
        <button @click="submitTel">点击修改</button>
      </div>
    </div>
    <div v-show="telStatus === 2">
      <el-form :model="firstRuleForm" ref="firstRuleForm" class="demo-ruleForm">
        <el-form-item>
          <span>账户验证</span>
          <br />
          <el-input
            v-model="firstRuleForm.password"
            placeholder="请输入账号登录密码"
            show-password
            type="password"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div class="btn">
        <button @click="submit">确定</button>
      </div>
    </div>
    <div v-show="telStatus === 3">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <el-form-item prop="telephone">
          <span>手机号</span>
          <br />
          <el-input
            v-model="ruleForm.telephone"
            placeholder="请输入新手机号"
          ></el-input>
        </el-form-item>
        <el-form-item prop="code">
          <span>验证码</span>
          <br />
          <el-input
            v-model="ruleForm.code"
            placeholder="请输入验证码"
          ></el-input>
          <div class="code">
            <span v-if="!codeStatus" @click="getCode">获取验证码</span>
            <span v-else class="hasCode">{{ totalTime }}s 后重试</span>
          </div>
        </el-form-item>
      </el-form>
      <div class="btn">
        <button @click="submitForm('ruleForm')">确定</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ClientPwd, getCodes, updateUserinfo, getDetail } from "@/api/personal";
export default {
  data() {
    return {
      ruleForm: {
        telephone: "",
        code: "",
      },
      firstRuleForm: {
        password: null,
      },
      NewForm: {
        telephone: "",
      },
      rules: {
        telephone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { min: 11, max: 11, message: "手机号格式错误", trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      telStatus: 1,
      codeStatus: false, // 判断是否点击了获取验证码
      totalTime: 60, // 验证码有效时长
    };
  },
  methods: {
    // 手机号验证
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // alert('submit!');
          const res = await updateUserinfo({
            id: Number(JSON.parse(localStorage.getItem("userInfo")).id),
            telephone: this.ruleForm.telephone,
            code: this.ruleForm.code,
          });
          console.log(res);
          if (res.code === 200) {
            this.ruleForm = {
              telephone: "",
              code: "",
            };
            this.$message({
              showClose: true,
              type: "success",
              message: "修改成功！请重新登录。",
            });
            this.getUserInfo();
            this.telStatus = 1;
            setTimeout(() => {
              let path = JSON.parse(localStorage.getItem("location"));
              // console.log(JSON.parse(localStorage.getItem('location')))
              if (path) {
                window.location.href = path;
              } else {
                window.location.href = "http://xbssc.zhihanyixing.com/";
              }
            }, 2000);
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
            });
          }
          //
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 验证当前账户
    async submit() {
      console.log(this.firstRuleForm.password);
      if (
        this.firstRuleForm.password === "" ||
        this.firstRuleForm.password == null
      ) {
        this.$message({
          showClose: true,
          message: "请输入登录密码！",
        });
      } else {
        const res = await ClientPwd({
          id: Number(JSON.parse(localStorage.getItem("userInfo")).id),
          password: this.firstRuleForm.password,
        });
        console.log(res);
        if (res.code === 200) {
          this.firstRuleForm.password = null;
          this.telStatus = 3;
        } else {
          this.$message({
            showClose: true,
            message: "验证失败！" + res.msg,
            type: "error",
          });
        }
      }

      // this.telStatus = 2;
    },
    // 确认新的手机号
    async submitTel() {
      this.telStatus = 3;
      // const res = await updateUserinfo({ id: Number(JSON.parse(localStorage.getItem('userInfo')).id), telephone: this.NewForm.telephone });
      // console.log(res);
      // if (res.code === 200) {
      //     this.$message({
      //         showClose: true,
      //         type: 'success',
      //         message: '修改成功！请重新登录'
      //     });
      //     setTimeout(() => {
      //         localStorage.clear();
      //         this.$router.push('/')
      //     }, 1000);
      // }
    },
    // 获取验证码
    async getCode() {
      if (this.ruleForm.telephone !== null && this.ruleForm.telephone !== "") {
        this.codeStatus = true;
        let clock = window.setInterval(() => {
          this.totalTime--;
          if (this.totalTime < 0) {
            //当倒计时小于0时清除定时器
            window.clearInterval(clock); //关
            this.totalTime = 60;
            this.codeStatus = false;
          }
        }, 1000);
        const res = await getCodes({ telephone: this.ruleForm.telephone });
        console.log(res);
        if (res.code !== 200) {
          this.$message({
            showClose: true,
            message: "验证码获取失败！" + res.msg,
          });
        }
      } else {
        this.$message({
          showClose: true,
          message: "请输入手机号！",
        });
      }
    },
    // 获取用户基础信息
    async getUserInfo() {
      const res = await getDetail({
        id: Number(JSON.parse(localStorage.getItem("userInfo")).id),
      });
      console.log(res);
      if (res.code === 200) {
        this.NewForm.telephone = res.data.telephone;
        localStorage.setItem("userInfo", JSON.stringify(res.data));
        // this.$forceUpdate();
      }
    },
  },
  created() {
    this.getUserInfo();
    // console.log(JSON.parse(localStorage.getItem('userInfo')))
    // this.NewForm.telephone =
  },
};
</script>

<style lang="scss" scoped>
.changeTel {
  padding: 40px 0px;
  width: 80%;
  margin: auto;
  // box-shadow: 0px 2px 4px 0px rgba(229,0,74,0.3);
  .el-form {
    span {
      font-size: 16px;
    }

    .el-select,
    .el-date-picker,
    .el-input {
      width: 100%;
      // height: 40px !important;
      // line-height: 40px !important;
    }

    .code {
      position: absolute;
      width: 100px;
      height: 45px;
      line-height: 45px;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      span {
        display: inline-block;
        border-left: 1px solid #e6e6e6;
        display: flex;
        align-items: center;
        justify-content: center;
        // text-align: center;
        height: 80%;
        width: 100%;
        font-size: 14px;
        color: var(--custom-color);
      }

      .hasCode {
        color: #7d7d7d;
      }
    }

    .el-form-item {
      position: relative;
    }
  }

  .btn {
    margin-top: 40px;

    button {
      width: 100%;
      height: 40px;
      background: var(--custom-color);
      border-radius: 4px;
      border: none;
      color: white;
      font-size: 14px;
    }
  }
}
</style>